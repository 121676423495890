<template>
    <div ref="prev" style="width: 100%; height: 100%">
        <b-row ref="bgprev" id="bg-prev" :style="myStyles">
            <div style="width: 100%; height: 100%">
                <div v-show="editDbMode" :style="editStyle">
                    <b-icon class="dragIcon" icon="arrows-move" font-scale="2" style="position: absolute; top: 5px; left: 5px" />
                    <div>
                        <p>{{opt.title}}</p>
                        <b-button variant="secondary" size="sm" @click="modalEdit = !modalEdit">Edit chart</b-button>
                    </div>
                </div>

                <apexcharts height="100%" v-show="!editDbMode" class="chart" typeof="line" :options="chartOptions" :series="series"></apexcharts>
            </div>
            <b-modal title="Edit component" v-model="modalEdit" @ok="handleOk" @cancel="handleCancel">
                <b-form-group>
                    <b-container>
                        <b-row>
                            <b-col cols="5" class="pr-2 pl-0">
                                <b-row>
                                    <b-col class="d-flex flex-row" cols="12">
                                        <resource-filter class="mr-2" />
                                        <strong style="white-space: nowrap">Select storages</strong>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12">
                                        <b-form-select size="sm" class="mt-1" v-model="storages" :options="storageOptions" multiple :select-size="10"></b-form-select>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="7" class="pl-2 pr-0">
                                <b-row>
                                    <b-col class="d-flex flex-row" cols="12">
                                        <strong>Chart options</strong>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-1">
                                    <b-col cols="12" class="d-inline-flex pr-0">
                                        <color-picker class="align-middle" id="titleColor" v-model="opt.titleColor" />
                                        <label size="sm" style="white-space: nowrap" for="title">Title:</label>
                                        <b-form-input class="w-50 ml-2" size="sm" id="title" type="text" v-model="opt.title"></b-form-input>
                                    </b-col>

                                </b-row>
                                <b-row class="mt-1">
                                    <b-col cols="12" class="d-inline-flex pr-0">
                                        <color-picker id="axisColors" v-model="opt.axisColors" />
                                        <label for="axisColors">Axis color</label>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-1">
                                    <b-col cols="12" class="d-inline-flex pr-0">
                                        <color-picker id="gridColor" v-model="opt.gridColor" />
                                        <label for="gridColor">Grid color</label>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-1">
                                    <b-col cols="12" class="d-inline-flex pr-0">
                                        <color-picker id="legendColor" v-model="opt.legendColor" />
                                        <label for="legendColor">Legend color</label>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-1">
                                    <b-col cols="12" class="d-inline-flex pr-0">
                                        <color-picker id="xLabelColor" v-model="opt.xLabelColor" />
                                        <label for="xLabelColor">x-axis label color</label>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-1">
                                    <b-col cols="12" class="d-inline-flex pr-0">
                                        <color-picker id="y1LabelColor" v-model="opt.y1LabelColor" />
                                        <label for="y1LabelColor">y-axis label color</label>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-1">
                                    <b-col cols="12" class="d-inline-flex pr-0">
                                        <color-picker id="bkgColor" v-model="opt.bkgColor" />
                                        <label for="bkgColor">Background color</label>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row class="mt-2">
                            <b-col>
                                <strong>Configuration templates</strong>
                            </b-col>
                        </b-row>
                        <b-row class="mt-1">
                            <b-col cols="4">
                                <label for="newTemplateName">New template:</label>
                            </b-col>
                            <b-col cols="4">
                                <b-form-input size="sm" id="newTemplateName" type="text" v-model="newTemplateName"></b-form-input>
                            </b-col>
                            <b-col cols="4">
                                <b-button variant="secondary" size="sm" @click="templateSave">Add</b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                <label for="selectTemplate">Select template:</label>
                            </b-col>
                            <b-col cols="4">
                                <b-form-select size="sm" id="selectTemplate" type="text" :options="confTemplates" @change="templateChange" v-model="selectedTemplate" :select-size="1"></b-form-select>
                            </b-col>
                            <b-col cols="4">
                                <b-button variant="secondary" size="sm" @click="updateTemplate">Update</b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-form-group>
                <b-form-group>
                    <b-container>
                        <strong>Line colors:</strong>
                        <b-row v-for="(storage, index) in seriesConf" :key="storage.storageId">
                            <b-col cols="4">
                                <div class="mt-1">{{ storageOptions.find(x => x.value === storage.storageId).text }}</div>
                            </b-col>
                            <b-col cols="2">
                                <color-picker id="axisColors" v-model="seriesConf[index].color" />
                            </b-col>
                            <b-col cols="6">
                            </b-col>
                        </b-row>
                    </b-container>
                </b-form-group>
                <b-form-group>
                    <b-form-checkbox v-model="remove"
                                     name="checkbox-remove"
                                     value="yes"
                                     unchecked-value="no">
                        Remove this component
                    </b-form-checkbox>
                </b-form-group>
                <template #modal-footer="{ ok, cancel}">
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button variant="secondary" size="sm" @click="cancel()">
                        Cancel
                    </b-button>
                    <b-button variant="primary" size="sm" @click="ok()">
                        Ok
                    </b-button>
                </template>
            </b-modal>
        </b-row>
    </div>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts'
    import { bus } from '@/main.js';
    import { axChart1Tmpl, isInSelectedCollectors, isInSelectedTypes } from '@/assets/l4a_jsAssets.js'
    import colorPicker from '@/components/Dashboard/colorPicker.vue';
    import filter from '@/components/Settings/filter.vue';

    export default {
        name: 'largeChart',
        props: ['dbid', 'itemid', 'comp'],
        data() {
            return {
                x: 0,
                y: 0,
                modalEdit: false,
                selected: '',
                remove: 'no',
                opt: {},
                selectedStorages: [],
                seriesConf: [],
                yaxisOptions: ['y1', 'y2'],
                y1Unit: '',
                y2Unit: '',
                series: [],
                seriesColors: [],
                yAxis: {},
                stroke: {
                    curve: 'straight',
                    width: 1
                },
                updateArgs: [true, true],
                newTemplateName: '',
                selectedTemplate: '-',
                colors: "#eaeaea"
            }
        },
        computed: {
            myStyles() {
                return {
                    'width': '100%',
                    'height': '100%',
                    'background-color': (this.editDbMode) ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0)',
                    'border-style': (this.editDbMode) ? 'solid' : 'none',
                    'border-width': '1px',
                    'display': 'flex',
                    'align-items': 'center',
                    'justify-content': 'center',
                    'margin-left': '0px'
                }
            },
            editStyle() {
                return {
                    'width': '100%',
                    'height': '100%',
                    'display': 'flex',
                    'align-items': 'center',
                    'justify-content': 'space-evenly'
                }
            },
            rfilter() {
                return this.$store.getters.filter
            },
            storageOptions() {
                let options = []
                let availableStorages = this.$store.getters.storages.filter(isInSelectedCollectors, {
                    selectedCollectors: this.rfilter.selectedCollectors,
                    changedCollectors: this.$store.getters.collectors,
                    changedSources: this.$store.getters.sources
                }).filter(isInSelectedTypes, {
                    selectedTypes: this.rfilter.selectedTypes,
                    changedSources: this.$store.getters.sources
                })

                for (let storage of this.seriesConf) {
                    options.push({ value: this.$store.getters.storages.find(x => x.placeId === storage.storageId).placeId, text: this.$store.getters.storages.find(x => x.placeId === storage.storageId).placeName })
                }

                for (let storage of availableStorages) {
                    if (options.findIndex(x => x.value === storage.placeId) === -1) {
                        options.push({ value: storage.placeId, text: storage.placeName })
                    }
                }
                return options.sort(function (a, b) {
                    var nameA = a.text.toLowerCase(), nameB = b.text.toLowerCase()
                    if (nameA < nameB) //sort string ascending
                        return -1
                    if (nameA > nameB)
                        return 1
                    return 0 //default return value (no sorting)
                })
            },
            confTemplates() {
                let options = []
                for (let template of this.$store.getters.confTemplates) {
                    if (template.type == this.comp.name) options.push({ value: template.name, text: template.name });
                }
                return options
            },
            storages: {
                get: function () {
                    if (this.selectedStorages.length == 0 && typeof this.comp.config.storages !== 'undefined') {
                        return this.comp.config.storages
                    } else {
                        return this.selectedStorages
                    }
                },
                set: function (newStorages) {
                    // Update seriesConf
                    let tempSeries = JSON.parse(JSON.stringify(this.seriesConf))
                    this.seriesConf = []
                    for (let storage of newStorages) {
                        let index = tempSeries.findIndex(x => x.storageId === storage)
                        if (typeof index === 'undefined' || index == -1) {
                            this.seriesConf.push({ storageId: storage, axis: 'y1', color: '#000000' })
                        } else {
                            this.seriesConf.push(tempSeries[index])
                        }
                    }
                    this.selectedStorages = newStorages

                }
            },
            editDbMode() {
                return this.$store.getters.editDbMode;
            },
            chartOptions() {
                let options = JSON.parse(JSON.stringify(axChart1Tmpl))
                //options.chart.height = this.y
                options.chart.background = this.opt.bkgColor
                options.colors = this.seriesColors
                options.xaxis.axisBorder.color = this.opt.axisColors
                options.xaxis.axisTicks.color = this.opt.axisColors
                options.grid.borderColor = this.opt.gridColor
                options.title.style.color = this.opt.titleColor
                options.title.text = this.opt.title
                options.xaxis.labels.style.colors = this.opt.xLabelColor
                options.stroke = this.stroke
                options.fill.colors = this.stroke.colors
                options.legend.labels.colors = this.stroke.colors
                options.yaxis = this.yAxis
                return options
            }

        },
        created() {
            if (typeof this.comp.config.series !== 'undefined') {
                this.seriesConf = JSON.parse(JSON.stringify(this.comp.config.series))
            }
            if (typeof this.comp.config.storages !== 'undefined') {
                this.selectedStorages = JSON.parse(JSON.stringify(this.comp.config.storages))
            }
            if (typeof this.comp.config.opt !== 'undefined') {
                this.opt = JSON.parse(JSON.stringify(this.comp.config.opt))
            } else {
                this.opt.axisColors = axChart1Tmpl.xaxis.axisBorder.color
                this.opt.bkgColor = axChart1Tmpl.chart.background
                this.opt.gridColor = axChart1Tmpl.grid.borderColor
                this.opt.titleColor = axChart1Tmpl.title.style.color
                this.opt.title = axChart1Tmpl.title.text
                this.opt.xLabelColor = axChart1Tmpl.xaxis.labels.style.colors
                this.opt.y1LabelColor = this.opt.axisColors
                this.opt.y2LabelColor = this.opt.axisColors
            }
        },
        mounted() {
            this.x = this.$refs.prev.clientWidth;
            this.y = this.$refs.prev.clientHeight;
            bus.$on('itemResized', (newSize) => {
                if (newSize.i == this.itemid) {
                    this.x = newSize.wpx
                    this.y = newSize.hpx
                }
            });
            bus.$on('newData', () => {
                if (this.$store.getters.data.length > 0 && typeof this.comp.config.storages !== 'undefined' && this.comp.config.storages.length > 0) {
                    let series = []
                    let yAxis = {}
                    let seriesColors = []
                    let seriesCurve = []
                    for (let storage of this.comp.config.storages) {
                        if (this.$store.getters.data.findIndex(x => x.placeId === storage) !== -1) {
                            let serieConfig = this.seriesConf.find(x => x.storageId === storage)
                            if (typeof yAxis.labels === 'undefined') {
                                let y1Unit = ''
                                let metricId = this.$store.getters.storages.find(x => x.placeId === storage).metricId
                                if (metricId !== 'tbd') {
                                    y1Unit = this.$store.getters.metrics.find(x => x.id === metricId).unit
                                }
                                yAxis = {
                                    forceNiceScale: true,
                                    labels: {
                                        style: {
                                            colors: this.opt.y1LabelColor
                                        },
                                        formatter: function (val) {
                                            return Math.round(val * 10) / 10 + ' ' + y1Unit;
                                        }
                                    },
                                    axisTicks: {
                                        show: true,
                                        color: this.opt.y1LabelColor
                                    },
                                    axisBorder: {
                                        show: true,
                                        color: this.opt.y1LabelColor
                                    }
                                }
                            }
                            seriesColors.push(serieConfig.color)
                            seriesCurve.push((this.$store.getters.data.find(x => x.placeId === storage).hcData < 400) ? 'smooth' : 'straight')
                            series.push({
                                name: this.$store.getters.storages.find(x => x.placeId === storage).placeName,
                                data: this.$store.getters.data.find(x => x.placeId === storage).hcData,
                            })
                        }
                    }
                    this.series = series
                    this.yAxis = yAxis
                    this.seriesColors = seriesColors
                    this.stroke.curve = seriesCurve
                }
            });
        },
        methods: {
            handleOk() {
                let payload = {
                    dbid: this.dbid,
                    itemid: this.itemid,
                    newConfig: {
                        storages: this.selectedStorages,
                        opt: this.opt,
                        series: this.seriesConf
                    }
                }
                this.$store.commit('updateCompConfig', payload);
                // add additional component
                if (this.selected != '') {
                    let compIndex = this.$store.getters.dbComponents.findIndex(x => x.name === this.selected);
                    let payload = {
                        dbid: this.dbid,
                        itemid: this.itemid,
                        newComp: this.$store.getters.dbComponents[compIndex]
                    }
                    this.selected = '';
                    this.$store.commit('addComp', payload);
                } else if (this.remove == 'yes') {
                    let payload = {
                        dbid: this.dbid,
                        itemid: this.itemid
                    }
                    this.remove = 'no'
                    this.$emit('removed', payload)
                }
                this.$store.commit('updateStorageIds', this.dbid)
            },
            handleCancel() {
                if (typeof this.comp.config.series !== 'undefined') {
                    this.seriesConf = JSON.parse(JSON.stringify(this.comp.config.series))
                }
                if (typeof this.comp.config.storages !== 'undefined') {
                    this.selectedStorages = JSON.parse(JSON.stringify(this.comp.config.storages))
                }
                if (typeof this.comp.config.opt !== 'undefined') {
                    this.opt = JSON.parse(JSON.stringify(this.comp.config.opt))
                }
            },
            templateChange() {
                let tmpl = JSON.parse(JSON.stringify(this.$store.getters.confTemplates.find(x => x.name === this.selectedTemplate).tmpl))
                tmpl.opt.title = this.opt.title
                this.opt = tmpl.opt
            },
            templateSave() {
                let opt = JSON.parse(JSON.stringify(this.opt))
                opt.title = 'tbd'
                let payload = {
                    name: this.newTemplateName,
                    type: this.comp.name,
                    tmpl: {
                        opt: opt
                    }
                }
                this.$store.commit('addConfTemplate', payload)
                this.selectedTemplate = this.newTemplateName
                this.newTemplateName = ''
            },
            updateTemplate() {
                let opt = JSON.parse(JSON.stringify(this.opt))
                opt.title = 'tbd'
                let payload = {
                    name: this.selectedTemplate,
                    type: this.comp.name,
                    tmpl: {
                        opt: opt
                    }
                }
                console.log(payload)
                this.$store.commit('updateConfTemplate', payload)
            }
        },
        components: {
            apexcharts: VueApexCharts,
            'color-picker': colorPicker,
            'resource-filter': filter
        }
    };
</script>

<style scoped>
    .pointer {
        cursor: pointer;
    }
</style>